<template>
  <div>
    <div
      v-b-toggle.collapse-edit-summary
      class="card-header-container"
    >
      <p class="w-100 font-poppins card-header-text text-start d-flex justify-content-between">
        <span> Credit Application Summary </span>
        <span><edit-summary-collapse-icon :class="isVisible ? 'top-arrow' : 'down-arrow'" /></span>
      </p>
    </div>
    <b-collapse
      id="collapse-edit-summary"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <k-form-group
          class="w-50 p-1"
          label-content="Payment Terms"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.paymentTerms"
            type="text"
            name="retailer-name"
            placeholder="Payment Terms"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Annual Revenue"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.annualRevenue"
            type="text"
            name="retailer-name"
            placeholder="Annual Revenue"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Way to secure credit"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.securityDocumentType"
            type="text"
            name="collection-name"
            placeholder="Way to secure credit"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Amount (credit limit)"
          label-class="font-work-sans"
        >
          <k-form-input
            type="text"
            name="amount"
            :value="formatCurrency(creditApplication.creditAmount, creditApplication.currency)"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Retailer Name"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.entityName"
            type="text"
            name="retailer-name"
            placeholder="Retailer Name"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Contact Name"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.firstName"
            type="text"
            name="retailer-name"
            placeholder="Contact Name"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Status"
          label-class="font-work-sans"
        >
          <b-dropdown
            class="w-100 k-search-dropdown"
            variant="none"
            toggle-class="drop-caret-icon text-black"
            :text="creditApplication.status || 'Select a status'"
          >
            <b-dropdown-item
              v-for="option in Object.values(CREDIT_APPLICATION_STATUS)"
              v-show="option !== CREDIT_APPLICATION_STATUS.PENDING"
              :key="option"
              :active="creditApplication.status === option"
              class="text-capitalize"
              @click="creditApplication.status = option"
            >
              {{ option }}
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle, BCollapse, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { constants, utils } from '@kingpin-global/kingpin-utils-frontend'

const { CREDIT_APPLICATION_STATUS } = constants
const { formatCurrency } = utils

export default {
  components: {
    BCollapse,
    BDropdown,
    BDropdownItem,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    creditApplication: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isVisible: true,
      formatCurrency,
      CREDIT_APPLICATION_STATUS,
    }
  },
}
</script>
