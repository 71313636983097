<template>
  <div class="h-100">
    <k-header-card class="">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <b-link
            class="text-primary d-flex align-items-center cursor-pointer text-decoration-none"
            :to="{ name: ROUTES.CREDIT_APPLICATION.LIST.name }"
          >
            <kingpin-icon
              class=""
              size="14"
              icon="ChevronLeftIcon"
            /> Back To Credit Applications
          </b-link>
          <span class="date-label"> Credit Application Id: {{ creditApplicationId }} </span>
        </div>
        <k-button
          variant="info"
          :disabled="disabled || isSaving"
          @click="updateStatus"
        >
          <kingpin-icon
            icon="FileV2Icon"
            size="16"
            class="me-2"
          />
          SAVE CHANGES
        </k-button>
      </div>
    </k-header-card>
    <div class="w-100 kp-body-container">
      <div
        v-if="isLoading"
        class="mx-1"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class="w-100 d-flex justify-content-between"
      >
        <div class="card-width d-flex flex-column">
          <div class="w-100 card-container">
            <k-card class="w-100 k-card-custom-class">
              <application-summary :credit-application="crediApplicationData" />
            </k-card>
          </div>
          <div class="w-100 card-container">
            <k-card class="w-100 k-card-custom-class">
              <application-contacts :credit-application="crediApplicationData" />
            </k-card>
          </div>
        </div>
        <div class="card-width  ps-4">
          <div class="w-100 card-container">
            <k-card class="w-100 k-card-custom-class">
              <retailer-profile :credit-application="crediApplicationData" />
            </k-card>
          </div>
          <div class="w-100 card-container">
            <k-card class="w-100 k-card-custom-class">
              <application-documents :credit-application="crediApplicationData" />
            </k-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KHeaderCard from '@/@core/components/KHeaderCard.vue'
import { apiToastErrorV2, apiToastSuccess } from '@/@core/utils/toast'
import { ROUTES } from '@/constants'
import store from '@/store'
import { GET_CREDIT_APPLICATION, UPDATE_CREDIT_APPLICATION_STATUS } from '@/store/modules/credit-application.module'
import ApplicationSummary from '@/views/credit-application/form-data/ApplicationSummary.vue'
import RetailerProfile from '@/views/credit-application/form-data/RetailerProfile.vue'
import { KButton, KCard, KingpinIcon } from '@kingpin-global/kingpin-ui'
import { BLink, BSpinner } from 'bootstrap-vue'
import ApplicationContacts from './form-data/ApplicationContacts.vue'
import ApplicationDocuments from './form-data/ApplicationDocuments.vue'

export default {
  components: {
    KButton,
    KingpinIcon,
    KCard,
    KHeaderCard,
    BLink,
    ApplicationSummary,
    BSpinner,
    RetailerProfile,
    ApplicationDocuments,
    ApplicationContacts,
  },
  data() {
    return {
      creditApplicationId: this.$route?.params?.id,
      ROUTES,
      crediApplicationData: {},
      isLoading: true,
      status: '',
      disabled: true,
      isSaving: false,
    }
  },
  watch: {
    crediApplicationData: {
      handler(val) {
        if (this.status !== val.status) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      },
      deep: true,
    },
  },
  created() {
    this.getCreditApplication()
  },
  methods: {
    getCreditApplication() {
      this.isLoading = true
      store
        .dispatch(GET_CREDIT_APPLICATION, this.creditApplicationId)
        .then(res => {
          this.crediApplicationData = res.data.data
          this.isLoading = false
          this.status = this.crediApplicationData.status
        })
        .catch(err => {
          apiToastErrorV2(err)
        })
    },
    updateStatus() {
      this.isSaving = true
      const payload = {
        status: this.crediApplicationData.status,
      }
      store
        .dispatch(UPDATE_CREDIT_APPLICATION_STATUS, {
          creditApplicationId: this.creditApplicationId,
          payload,
        })
        .then(res => {
          apiToastSuccess(res.data.message)
          this.status = this.crediApplicationData.status
          this.disabled = true
          this.isSaving = false
        })
        .catch(err => {
          this.isLoading = false
          apiToastErrorV2(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-details-tab.scss";
</style>
