<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.6249 6.26786C5.6249 5.96949 5.74342 5.68334 5.9544 5.47236C6.16538 5.26138 6.45153 5.14286 6.7499 5.14286H7.87489C8.17326 5.14286 8.45941 5.26138 8.67039 5.47236C8.88137 5.68334 8.9999 5.96949 8.9999 6.26786V7.32311L9.44989 9.12198C9.50596 9.34705 9.49109 9.58396 9.40731 9.80025C9.32353 10.0165 9.17494 10.2017 8.98189 10.3302L7.93564 11.0277C7.75077 11.1511 7.53351 11.2169 7.31127 11.2169C7.08903 11.2169 6.87177 11.1511 6.68689 11.0277L5.64177 10.3302C5.44892 10.2015 5.30057 10.0163 5.21699 9.80004C5.13342 9.58376 5.11872 9.34694 5.1749 9.12198L5.6249 7.32311V6.26786ZM7.87489 6.26786H6.7499V7.32311C6.74989 7.41527 6.73855 7.50708 6.71615 7.59648L6.26615 9.39423L7.3124 10.0917L8.35752 9.39423L7.90752 7.59648C7.88549 7.50704 7.87454 7.41522 7.87489 7.32311V6.26786Z"
      fill="#323C47"
    />
    <path
      d="M15.7499 5.0625V15.75C15.7499 16.3467 15.5128 16.919 15.0909 17.341C14.6689 17.7629 14.0966 18 13.4999 18H8.9999L3.3749 12.9375H2.2499V2.25C2.2499 1.65326 2.48695 1.08097 2.90891 0.65901C3.33086 0.237053 3.90316 0 4.4999 0H10.6874L15.7499 5.0625ZM12.3749 5.0625C11.9273 5.0625 11.4981 4.88471 11.1817 4.56824C10.8652 4.25177 10.6874 3.82255 10.6874 3.375V1.125H8.4374V2.25H7.3124V3.375H8.4374V4.5H6.7499L6.7499 3.375H5.6249V2.25H6.7499V1.125H4.4999C4.20153 1.125 3.91538 1.24353 3.7044 1.4545C3.49342 1.66548 3.3749 1.95163 3.3749 2.25V12.9375L8.9999 18V16.875H13.4999C13.7983 16.875 14.0844 16.7565 14.2954 16.5455C14.5064 16.3345 14.6249 16.0484 14.6249 15.75V5.0625H12.3749Z"
      fill="#323C47"
    />
    <path
      d="M0.338055 18V17.5237L2.5031 14.4184H0.325684V13.7771H3.4516V14.2534L1.28861 17.3587H3.46397V18H0.338055Z"
      fill="#323C47"
    />
    <path
      d="M4.85307 13.7771V18H4.08808V13.7771H4.85307Z"
      fill="#323C47"
    />
    <path
      d="M5.56533 18V13.7771H7.1489C7.47332 13.7771 7.74549 13.8376 7.96544 13.9586C8.18675 14.0795 8.35377 14.2459 8.46649 14.4576C8.58058 14.6679 8.63763 14.9071 8.63763 15.1751C8.63763 15.4459 8.58058 15.6865 8.46649 15.8968C8.3524 16.1071 8.184 16.2728 7.96131 16.3937C7.73862 16.5133 7.46438 16.5731 7.13859 16.5731H6.08906V15.9442H7.0355C7.2252 15.9442 7.38053 15.9112 7.5015 15.8453C7.62246 15.7793 7.71182 15.6886 7.76955 15.5731C7.82866 15.4576 7.85821 15.325 7.85821 15.1751C7.85821 15.0253 7.82866 14.8933 7.76955 14.7792C7.71182 14.6651 7.62178 14.5765 7.49943 14.5132C7.37847 14.4486 7.22245 14.4163 7.03137 14.4163H6.33031V18H5.56533Z"
      fill="#323C47"
    />
  </svg>
</template>
<script>
export default {
  name: 'ZipIcon',
}
</script>
