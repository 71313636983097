<template>
  <div
    class="
      w-100
      d-flex
      flex-row
      justify-content-between
      file-item
    "
  >
    <div class="d-flex flex-row">
      <div v-if="Object.values(fileTypes).includes(fileType)">
        <pdf-icon v-if="fileType===fileTypes.PDF" />
        <zip-icon v-if="fileType===fileTypes.ZIP" />
      </div>
      <div v-else>
        <b-icon
          icon="file-earmark"
          scale="1"
          variant="info"
        />
      </div>
      <div>
        <span class="font-inter file-name">{{ fileName }}</span>
      </div>
    </div>
    <div class="d-flex action-icons">
      <div
        v-if="!hideDownload"
        class="download-icon cursor-pointer"
        @click="$emit('on-download')"
      >
        <cloud-download-icon />
      </div>
      <div
        v-if="!hideDelete"
        class="delete-icon cursor-pointer"
        @click="$emit('on-delete')"
      >
        <parcel-delete-icon />
      </div>
    </div>
  </div>
</template>
<script>
import PdfIcon from '@/assets/images/svg/PdfIcon.vue'
import ParcelDeleteIcon from '@/assets/images/svg/ParcelDeleteIcon.vue'
import CloudDownloadIcon from '@/assets/images/svg/CloudDownloadIcon.vue'
import ZipIcon from '@/assets/images/svg/ZipIcon.vue'
import { BIcon } from 'bootstrap-vue'

const fileTypes = {
  PDF: 'pdf',
  ZIP: 'zip',
}
export default {
  name: 'FileItem',
  components: {
    PdfIcon, ZipIcon, ParcelDeleteIcon, CloudDownloadIcon, BIcon,
  },
  props: {
    fileName: {
      type: String,
      default: '',
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    hideDownload: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: fileTypes.PDF,
    },
  },
  data() {
    return {
      fileTypes,
    }
  },
  emits: ['on-delete', 'on-download'],
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/components/file-item.scss";
</style>
