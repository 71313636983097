<template>
  <div>
    <div
      v-b-toggle.collapse-references-section
      class="card-header-container"
    >
      <p class="w-100 font-poppins card-header-text text-start d-flex justify-content-between">
        <span> Reference section </span>
        <span><edit-summary-collapse-icon :class="isVisible ? 'top-arrow' : 'down-arrow'" /></span>
      </p>
    </div>
    <b-collapse
      id="collapse-references-section"
      v-model="isVisible"
      class="card-body-container"
    >
      <template v-if="hasData">
        <div
          v-for="contact in creditApplication.contacts"
          :key="contact._id"
        >
          <p class="contact-heading">
            {{ contact.role }} Contact:
          </p>
          <div class="d-flex justify-content-between flex-wrap">
            <k-form-group
              v-if="contact.companyName"
              class="w-50 p-1"
              label-content="Company Name"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="contact.companyName"
                type="text"
                placeholder="Company Name"
                class="text-capitalize"
                disabled
              />
            </k-form-group>
            <k-form-group
              v-if="contact.name"
              class="w-50 p-1"
              label-content="Name"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="contact.name"
                type="text"
                placeholder="Name"
                class="text-capitalize"
                disabled
              />
            </k-form-group>
            <k-form-group
              v-if="contact.email"
              class="w-50 p-1"
              label-content="Email"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="contact.email"
                type="text"
                placeholder="Email"
                class="text-capitalize"
                disabled
              />
            </k-form-group>
            <k-form-group
              v-if="contact.phone"
              class="w-50 p-1"
              label-content="Phone"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="contact.phone"
                type="text"
                placeholder="Phone"
                class="text-capitalize"
                disabled
              />
            </k-form-group>
          </div>
        </div>
      </template>
      <div v-else>
        No information provided
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle, BCollapse,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { utils } from '@kingpin-global/kingpin-utils-frontend'

const { formattedDate } = utils

export default {
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    creditApplication: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isVisible: true,
      formattedDate,
      hasData: true,
    }
  },
  created() {
    const roles = Object.values(this.creditApplication.contacts.map(_ => _?.role))
    this.hasData = !!roles.length
    this.isVisible = this.hasData
  },
}
</script>
