<template>
  <div>
    <div
      v-b-toggle.collapse-application-documents
      class="card-header-container"
    >
      <p class="w-100 font-poppins card-header-text text-start d-flex justify-content-between">
        <span> Documents Uploaded </span>
        <span><edit-summary-collapse-icon :class="isVisible ? 'top-arrow' : 'down-arrow'" /></span>
      </p>
    </div>
    <b-collapse
      id="collapse-application-documents"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="d-flex flex-wrap">
        <k-form-group
          class="m-1 w-100"
          label-content="Security Content"
          label-class="font-work-sans"
        >
          <file-item
            v-if="creditApplication.securityDocument"
            :file-name="FILE_TYPES.SECURITY_DOCUMENT"
            hide-delete
            @on-download="download(creditApplication.securityDocument)"
          />
          <span v-else> No document uploaded </span>
        </k-form-group>
        <k-form-group
          class="m-1 w-100"
          label-content="Proof of Address"
          label-class="font-work-sans"
        >
          <file-item
            v-if="creditApplication.proofOfAddress"
            :file-name="FILE_TYPES.PROOF_OF_ADDRESS"
            hide-delete
            @on-download="download(creditApplication.proofOfAddress)"
          />
          <span v-else> No document uploaded </span>
        </k-form-group>
        <k-form-group
          class="m-1 w-100"
          label-content="Proof of Identity"
          label-class="font-work-sans"
        >
          <file-item
            v-if="creditApplication.proofOfIdentity"
            :file-name="FILE_TYPES.PROOF_OF_IDENTITY"
            hide-delete
            @on-download="download(creditApplication.proofOfIdentity)"
          />
          <span v-else> No document uploaded </span>
        </k-form-group>
        <k-form-group
          class="m-1 w-100"
          label-content="Bank Statement"
          label-class="font-work-sans"
        >
          <file-item
            v-if="creditApplication.bankStatement"
            :file-name="FILE_TYPES.BANK_STATEMENT"
            hide-delete
            @on-download="download(creditApplication.bankStatement)"
          />
          <span v-else> No document uploaded </span>
        </k-form-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle, BCollapse,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup } from '@kingpin-global/kingpin-ui'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import FileItem from '@/components/file-item/FileItem.vue'
import { download } from '@/common-utils'
import { FILE_TYPES } from '@/constants'

const { formattedDate } = utils

export default {
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    KFormGroup,
    FileItem,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    creditApplication: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isVisible: true,
      formattedDate,
      download,
      FILE_TYPES,
    }
  },
}
</script>
