import { render, staticRenderFns } from "./FileItem.vue?vue&type=template&id=48ea2f80"
import script from "./FileItem.vue?vue&type=script&lang=js"
export * from "./FileItem.vue?vue&type=script&lang=js"
import style0 from "./FileItem.vue?vue&type=style&index=0&id=48ea2f80&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports