<template>
  <div>
    <div
      v-b-toggle.collapse-retailer-profile
      class="card-header-container"
    >
      <p class="w-100 font-poppins card-header-text text-start d-flex justify-content-between">
        <span> Retailer Profile </span>
        <span><edit-summary-collapse-icon :class="isVisible ? 'top-arrow' : 'down-arrow'" /></span>
      </p>
    </div>
    <b-collapse
      id="collapse-retailer-profile"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <k-form-group
          class="w-50 p-1"
          label-content="Store Type"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.storeType"
            type="text"
            name="retailer-name"
            placeholder="Store Type"
            class="text-capitalize"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Date of Incorporation"
          label-class="font-work-sans"
        >
          <k-form-input
            :value="formattedDate(creditApplication.retailer.dateOfIncorporation)"
            type="text"
            name="retailer-name"
            placeholder="Annual Revenue"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Billing Address Line 1"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.billingAddressLine1"
            type="text"
            name="retailer-name"
            placeholder="Billing Address Line 1"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Billing Address Line 2"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.billingAddressLine2"
            type="text"
            name="retailer-name"
            placeholder="Billing Address Line 2"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Billing City"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.billingCity"
            type="text"
            name="collection-name"
            placeholder="Billing City"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Billing Country"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.billingCountry"
            type="text"
            name="amount"
            placeholder="Billing Country"
            disabled
          />
        </k-form-group>

        <k-form-group
          class="w-50 p-1"
          label-content="Billing Zipcode"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.billingZipcode"
            type="text"
            name="retailer-name"
            placeholder="Billing Zipcode"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Billing Contact Name"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.billingContactName"
            type="text"
            name="retailer-name"
            placeholder="Billing Contact Name"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Billing Contact Number"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.billingContactNumber"
            type="text"
            name="retailer-name"
            placeholder="Billing Contact Number"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="w-50 p-1"
          label-content="Billing Contact Email"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="creditApplication.retailer.billingContactEmail"
            type="text"
            name="retailer-name"
            placeholder="Billing Contact Email"
            disabled
          />
        </k-form-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle, BCollapse,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { utils } from '@kingpin-global/kingpin-utils-frontend'

const { formattedDate } = utils

export default {
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    creditApplication: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isVisible: true,
      formattedDate,
    }
  },
}
</script>
