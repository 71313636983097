<template>
  <div class="k-header-card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'KHeaderCard',
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables-components.scss";

.k-header-card {
  padding: $k-header-card-padding;
  background: $white;
  box-shadow: 0px 2px 4px $header-box-shadow;
  position: sticky;
  top: $min-height-nav-bar;
  z-index: 3;
}
</style>
